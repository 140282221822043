$black: #0F0F0F;
$darkgreen: #031D20;


$blue1: #2A2945;
$grey1: #F2F2FA;
$grey2: #868B96;

$grey3: #3C4852;
$grey4: #E6E6E6;
$grey5: #bcc2c8;
$grey6: #F7F9FD;
$grey7: #f7f5f2;
$bluegray: rgb(250, 250, 250);

$blue2: #2D81F7;
$blue3: rgba(45, 129, 247, .17);


$white1: #F0F3FA;
$white: #FFF;

$light-green: rgb(172, 205, 172);
$light-red: rgb(218, 174, 174);

$light-color: #217C79;
$peacock: #062A30;
$secondary: #505E60;


$correct: $light-green;
$incorrect: $light-red;

.selected-item-color {
    color: $blue2;
}

.default-item-color {
    color: $blue3;
}

.list-item-selected .mat-mdc-list-item-unscoped-content .mat-icon {
    color: #287c7c;
}

.list-item-default .mat-mdc-list-item-unscoped-content .mat-icon {
    color: rgba(40, 124, 124, .5);
}

.c-lable {
    color: $blue1
}

.bg-button {
    background-color: $blue1;
}

.bg-default {
    background-color: $white !important;
}

.bg-specific {
    background-color: $bluegray ;
}

.text-light-color {
    color: $light-color ;
}

// .text-dark-c{
//     color: #0F0F0F;
// }
.obg-color {
    color: $white1 !important;
}

.bg-container {
    background-color: $grey4 !important;
}

.bg-container-f {
    background-color: $grey7 !important;
}

.text-color-pc {
    color: $peacock;
}

.bg-color-grey {
    background-color: $grey7 !important;
    min-height: 100%;
}

.bgcolor-btn {
    background-color: $peacock;
}

.t-sec {
    color: $secondary;
}