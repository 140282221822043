// TODO: Filter out what is actually an Angular material class and what not

@media screen and (max-width: 768px) {

    .mat-mdc-text-field-wrapper {
        width: 82%;
    }
}

.logoutParent.mat-mdc-menu-item.mdc-list-item {
    display: flex;
    align-items: stretch !important;
    justify-content: center;
    flex: 1;

    span {
        display: flex;
        width: 100%;
    }

    a {
        display: flex;
        flex: 1;
        text-decoration: none;
        align-items: center;
        color: black;
    }
}



.courses-card {
    // max-width: 20em;

    p,
    h3 {
        margin: .4em;
    }

    p {
        color: $grey2;
    }

    // .mat-mdc-card-content {
    //     margin-top: 1em;
    // }
}

.continue-learning-card .mat-mdc-card-content {
    margin-top: 0;
}



.sidepanel-courses {
    .mat-mdc-list-item-unscoped-content {
        display: flex;
        justify-content: flex-start;
    }
}

.certification-content {
    padding: 0 2em;
    min-width: 60%;

    h4,
    .mat-icon {
        @extend %flex-with-centered-content;
    }

    ul {
        padding-inline-start: 0;

        li {
            @extend %flex-with-centered-content;
        }
    }

}

.mat-paginator-range-label {
    display: none;
}

.mat-button-toggle-checked {
    background-color: #217c79 !important;
    color: $grey1 !important;
}

mat-button-toggle-group mat-pseudo-checkbox{
    display: none !important;
}