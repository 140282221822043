// Background colors
// TODO: Replace this with theming
.bg-color-white {
    background-color: $white;
}

// Extra Tailwind class overrides for overriding Angular Material styles
.h-auto-important {
    height: auto !important;
}

.w-auto-important {
    width: auto !important;
}


// TODO: Everything below here either needs to be categorized or deleted

.img-2 {
    width: 80%;
    object-fit: cover;
}

.img-3 {
    object-fit: cover;
    width: 120px;
}

.input-border {
    border: 1px solid rgba(212, 212, 212, 0.815);
    border-radius: 4px;
}

.inpt-b-d {
    border-bottom: 1px dotted black;
}

.border-element {
    border-radius: 12px;
}

.container-duo {
    height: 100%;
    width: 100%;
    align-content: stretch;
    display: flex;

    .container-duo-inner {
        display: flex;
        width: 50%;
        justify-content: center;
        align-items: center;
    }

    div:first-child {
        background-color: black;
        color: white;
    }
}

.a-none {
    text-decoration: none;
}

.completed {
    opacity: 0.5;
}

.completed-icon {
    color: rgb(6, 7, 6);
    font-size: 24px;
    margin-left: 10px;
}

.item-b {
    border-radius: 8px;
}

.card-w {
    width: 82%;
    margin-left: 5%;
}

.card-n-w {
    max-width: 100%;
    // margin-left: 2.4%;
}

.fnd-btn1 {
    border: 1px solid rgb(212, 212, 212);
    padding: .5em;
    border-radius: 5px;
    background: none;
}


.fnd-btn {
    width: 80%;
    border: 1px solid #062A30;
    padding: .5em;
    border-radius: 10px;
    background: none;
    margin: auto;
    color: #062A30;
    cursor: pointer;
}

// .item-hr{
//     border: 1px solid lightgray;
//     display: flex;
//     align-items: center;
//     border-radius: 10px;
// }


.background-img {
    background-image: url('/assets/Fcerti.png/');
    width: 50%;
    height: 495px;
    background-size: cover;
    position: relative;
}

.mat-form-field {
    display: flex;
    flex-direction: column-reverse;
    /* Reverse the order of child elements */
    position: relative;
    /* Ensure the position context for absolute positioning */
    margin-top: 2em;
}

.mat-hint {
    position: absolute;
    /* Position the hint absolutely */
    bottom: 5em;
    /* Adjust this value as needed to place the hint above the input */
    left: 0;
}


.transp-50 {
    opacity: 50%;
}


input[type='radio'] {
    accent-color: $light-color;
}