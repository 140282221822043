.form-page-grey {
    // background-color: $grey6 !important;
    border-radius: 10px !important;
    padding: 2em;

    form {
        width: 100%;
    }
}

// .form-basic {
//     border-radius: 10px !important;
//     padding: 2em;

//     form{
//         width: 100%;
//     }

// }

.form-field-w2-h2 {
    .mat-mdc-form-field-infix {
        width: 10em !important;
        min-height: .7em !important;
    }
}

.form-field-h2 {
    .mat-mdc-form-field-infix {
        min-height: .7em !important;
    }
}

.mdc-text-field {
    background-color: white;

}

@media screen and (max-width: 768px) {
    .form-page-grey {
        padding: 0.6em;
    }


    .form-page-grey-f {
        grid-template-columns: 1fr;

    }

    .form-field-w2-h2 {
        width: 80% !important;

        .mat-mdc-form-field-infix {
            width: 6em !important;
            min-height: .7em !important;
        }
    }


    .card-w {
        width: 82%;
        margin-left: 0;
    }

    .card-n-w {
        max-width: 100%;
        margin-left: 0;
    }

    .max-width-80 {
        max-width: 80%;
        position: relative;
        left: 0;
    }

    .max-width-80-1 {
        max-width: 80%;

    }

    .max-width-80-1n {
        max-width: 100%;

    }

    .max-width-80-2 {
        max-width: 87%;
        position: relative;
        left: 0;
    }


    textarea {
        width: 90%;
    }

    input {
        width: 90%;
    }

}