.quiz {
    :hover {
        @include answer-selection;
    }

    // TODO: Angular theming instead of custom class overrides
    .mat-mdc-button {
        height: 6em !important;
    }

    .mdc-button__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
}

.answer-selection {
    @include answer-selection;
}

.result-card {
    height: 15em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result-card.correct {
    background: linear-gradient($correct, $white);
}

.result-card.incorrect {
    background: linear-gradient($incorrect, $white);
}