.button-rounded {

  &.mat-mdc-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-flat-button {
    border-radius: 30px;
    //   25% / 50%;
  }
}


.button-lr-padding-3em {
  padding: 0 3em !important;
}

.grid-container-2c-2em-gap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
}

.button-not-started {
  width: 120px;
  height: 40px;
  border-radius: 22px;
  background: transparent;
  border: 1px solid rgb(168, 168, 168);
}

.btn {
  padding: .5em;
  width: 130px;
  border: none;
  height: 34px;
  border-radius: 3px;
  cursor: pointer;
}