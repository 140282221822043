.icon {
    position: relative;
    top: .4em;
    font-size: 20px;
}



@media only screen and (max-width: 1024px) {
    .mat-divider {
        margin-top: 3em !important;
    }
}