@font-face {
    font-family: "Roobert";
    src: url("/assets/fonts/Roobert-Regular.otf");
}

@font-face {
    font-family: "RoobertBold";
    src: url("/assets/fonts/Roobert-Bold.otf");
}

.bold {
    font-family: "RoobertBold";
}

body * {
    font-family: "Roobert";
}

.text-justify {
    text-align: justify;
    text-decoration: none;

}


.item-name {
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;

}