@import "./styles/main.scss";


// testinf the tailwind css
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-size: .8em;
    overflow-x: hidden;
}