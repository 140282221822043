@mixin answer-selection {
    border: 1px solid $blue1 !important;

    * {
        border: none !important;
    }
}

%flex-with-centered-content {
    display: flex;
    align-items: center;
    line-height: normal;
}